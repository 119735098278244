import PAYMENT_API from '../../../api/paymentAPI';
import { PaymentActionType } from '../ActionTypes';

const getTransactions = (params: any, qrId: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PaymentActionType.GET_TRANSACTIONS,
    });
    let res;
    if (qrId) {
      res = await PAYMENT_API.getTransactionsForQR(params, qrId);
    } else {
      res = await PAYMENT_API.getTransactions(params);
    }
    dispatch({
      type: PaymentActionType.TRANSACTIONS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: PaymentActionType.TRANSACTIONS_FAILED,
      payload: e,
    });
  }
};

const getTransactionsDetails =
  (transactionsId: string) => async (dispatch: any) => {
    try {
      dispatch({
        type: PaymentActionType.GET_TRANSACTIONS_DETAILS,
      });
      const res = await PAYMENT_API.getTransactionsDetails(transactionsId);
      dispatch({
        type: PaymentActionType.TRANSACTIONS_DETAIL_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: PaymentActionType.TRANSACTIONS_DETAIL_FAILED,
        payload: e,
      });
    }
  };
const getRefundTransactions = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PaymentActionType.GET_REFUND_TRANSACTIONS,
    });
    const res = await PAYMENT_API.getRefundTransactions(params);
    dispatch({
      type: PaymentActionType.REFUND_TRANSACTIONS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: PaymentActionType.REFUND_TRANSACTIONS_FAILED,
      payload: e,
    });
  }
};

const getRefundTransactionDetails = (id: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PaymentActionType.GET_REFUND_TRANSACTIONS_DETAILS,
    });
    const res = await PAYMENT_API.getRefundTransactionsDetails(id);
    dispatch({
      type: PaymentActionType.REFUND_TRANSACTIONS_DETAIL_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PaymentActionType.REFUND_TRANSACTIONS_DETAIL_FAILED,
      payload: e,
    });
  }
};

const getUpdatePaymentStatus =
  (transactionsId: string, recheck: boolean, entity: string) =>
  async (dispatch: any) => {
    try {
      dispatch({
        type: PaymentActionType.GET_UPDATE_PAYMENT_STATUS,
      });
      const res = await PAYMENT_API.getUpdatePaymentStatus(
        transactionsId,
        recheck,
        entity
      );
      dispatch({
        type: PaymentActionType.GET_UPDATE_PAYMENT_STATUS_SUCCESS,
        payload: res.data.data,
      });
    } catch (e) {
      dispatch({
        type: PaymentActionType.GET_UPDATE_PAYMENT_STATUS_FAILED,
        payload: e,
      });
    }
  };

const resetPaymentStatus = (dispatch: any) => {
  dispatch({
    type: PaymentActionType.RESET_PAYMENT_STATUS,
  });
};

const getDisputes = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: PaymentActionType.GET_DISPUTES,
    });
    const res = await PAYMENT_API.getDisputes(params);
    dispatch({
      type: PaymentActionType.DISPUTES_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PaymentActionType.DISPUTES_FAILED,
      payload: e,
    });
  }
};
const getDowntimes = (driverId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: PaymentActionType.GET_DOWNTIMES,
    });
    const res = await PAYMENT_API.getDowntimes(driverId);
    dispatch({
      type: PaymentActionType.GET_DOWNTIMES_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PaymentActionType.GET_DOWNTIMES_FAILED,
      payload: e,
    });
  }
};

const getDisputeDetails = (disputeId: string) => async (dispatch: any) => {
  try {
    dispatch({
      type: PaymentActionType.GET_DISPUTES_DETAILS,
    });
    const res = await PAYMENT_API.getDisputeDetails(disputeId);
    dispatch({
      type: PaymentActionType.GET_DISPUTES_DETAILS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PaymentActionType.GET_DISPUTES_DETAILS_FAILED,
      payload: e,
    });
  }
};

const acceptDisputeById = (disputeId: string) => async (dispatch: any) => {
  dispatch({
    type: PaymentActionType.ACCEPT_DISPUTE,
  });
  // eslint-disable-next-line consistent-return
  return PAYMENT_API.acceptDispute(disputeId, {}).then(response => {
    if (response) {
      dispatch({
        type: PaymentActionType.ACCEPT_DISPUTE_SUCCESS,
        payload: response.data.data,
      });
      return response;
    }
    dispatch({
      type: PaymentActionType.ACCEPT_DISPUTE_FAILED,
      payload: '',
    });
  });
};

const contestDispute = (disputeId: string) => async (dispatch: any) => {
  dispatch({
    type: PaymentActionType.CONTEST_DISPUTE,
  });
  // eslint-disable-next-line consistent-return
  return PAYMENT_API.contestDispute(disputeId, {}).then(response => {
    if (response) {
      dispatch({
        type: PaymentActionType.CONTEST_DISPUTE_SUCCESS,
        payload: response,
      });
      return response;
    }
    dispatch({
      type: PaymentActionType.CONTEST_DISPUTE_FAILED,
      payload: '',
    });
  });
};

const uploadDisputeEvidence = (data: any) => async (dispatch: any) => {
  dispatch({
    type: PaymentActionType.DISPUTE_EVIDENCE,
  });
  // eslint-disable-next-line consistent-return
  return PAYMENT_API.disputeEvidence(data).then(response => {
    if (response) {
      dispatch({
        type: PaymentActionType.DISPUTE_EVIDENCE_SUCCESS,
        payload: response,
      });
      return response;
    }
    dispatch({
      type: PaymentActionType.DISPUTE_EVIDENCE_FAILED,
      payload: '',
    });
  });
};

const getQrCodes =
  (storeId: string | undefined, params: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: PaymentActionType.GET_QR_CODES,
      });
      let res;
      if (storeId) {
        const parameters = { ...params, id: storeId };
        res = await PAYMENT_API.getQrCodesForStore(parameters);
      } else {
        res = await PAYMENT_API.getQrCodes(params);
      }
      dispatch({
        type: PaymentActionType.GET_QR_CODES_SUCCESS,
        payload: res,
      });
    } catch (e) {
      dispatch({
        type: PaymentActionType.GET_QR_CODES_FAILED,
        payload: e,
      });
    }
  };

const getQRDetails = (qrId: string | undefined) => async (dispatch: any) => {
  try {
    dispatch({
      type: PaymentActionType.GET_QR_DETAILS,
    });
    const res = await PAYMENT_API.getQRDetails(qrId);
    dispatch({
      type: PaymentActionType.GET_QR_DETAILS_SUCCESS,
      payload: res.data.data,
    });
  } catch (e) {
    dispatch({
      type: PaymentActionType.GET_QR_DETAILS_FAILED,
      payload: e,
    });
  }
};

const resetQRDetails = () => (dispatch: any) => {
  dispatch({
    type: PaymentActionType.RESET_QR_DETAILS,
  });
};

export {
  getTransactions,
  getRefundTransactions,
  getTransactionsDetails,
  getRefundTransactionDetails,
  getUpdatePaymentStatus,
  resetPaymentStatus,
  getDisputes,
  getDisputeDetails,
  acceptDisputeById,
  uploadDisputeEvidence,
  contestDispute,
  getDowntimes,
  getQrCodes,
  getQRDetails,
  resetQRDetails,
};

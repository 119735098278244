import axios from './axios';
import config from './config';

const RECOMMENDATION_API = {
  getRecommendations: (params: any) => {
    console.log('params', params);
    const rr = axios({
      url: `${config.endpoint.recommendation}`,
      method: 'GET',
      params,
    });
    rr.then(res => console.log('jel;lo', res));
    return rr;
  },
  getRecommendationDetail: (recommendationId: string) =>
    axios({
      url: `${config.endpoint.recommendation}/${recommendationId}`,
      method: 'GET',
    }),
  createRecommendation: (data: any) =>
    axios({
      url: `${config.endpoint.recommendation}/create`,
      method: 'POST',
      data,
    }),

  updateRecommendation: (data: any) =>
    axios({
      url: `${config.endpoint.recommendation}/update`,
      method: 'POST',
      data,
    }),
};

export default RECOMMENDATION_API;

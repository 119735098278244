import BANNERS_API from '../../../api/bannersAPI';
import { BannerActionTypes } from '../ActionTypes';

const getBanners = (params: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: BannerActionTypes.GET_BANNERS,
    });
    const res = await BANNERS_API.getBanners(params);
    dispatch({
      type: BannerActionTypes.GET_BANNERS_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: BannerActionTypes.GET_BANNERS_FAILURE,
      payload: e,
    });
  }
};

const getBannerDetails = (recommendationId: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: BannerActionTypes.GET_BANNER_DETAIL,
    });
    const res = await BANNERS_API.getBannerDetails(recommendationId);
    dispatch({
      type: BannerActionTypes.GET_BANNER_DETAIL_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: BannerActionTypes.GET_BANNER_DETAIL_FAILURE,
      payload: e,
    });
  }
};

const updateBanner = (data: any) => async (dispatch: any) => {
  try {
    dispatch({
      type: BannerActionTypes.UPDATE_BANNER_SUCCESS,
    });

    const res = await BANNERS_API.updateBanner(data);

    dispatch({
      type: BannerActionTypes.UPDATE_BANNER_SUCCESS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: BannerActionTypes.UPDATE_BANNER_FAILURE,
      payload: e,
    });
  }
};

export default {
  getBanners,
  getBannerDetails,
  updateBanner,
};

import axios from './axios';
import config from './config';

const BANNERS_API = {
  getBanners: (params: any) => {
    const res = axios({
      url: `${config.endpoint.banners}`,
      method: 'GET',
      params,
    });
    return res;
  },
  postBanner: async (data: object) => {
    const res = await axios({
      url: `${config.endpoint.banners}/create`,
      method: 'POST',
      data,
    });
    return res.data;
  },

  getBannerDetails: (bannerId: string) =>
    axios({
      url: `${config.endpoint.banners}/${bannerId}`,
      method: 'GET',
    }),

  updateBanner: async (data: object) =>
    axios({
      url: `${config.endpoint.banners}/update`,
      method: 'POST',
      data,
    }),
};

export default BANNERS_API;

import {
  BarcodeOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  ShopOutlined,
  SettingOutlined,
  TagOutlined,
  TeamOutlined,
  AuditOutlined,
  FileExcelOutlined,
  FileMarkdownOutlined,
  WalletOutlined,
  TagsOutlined,
  MobileOutlined,
  NotificationOutlined,
  ForkOutlined,
  EditFilled,
  ClusterOutlined,
  SaveOutlined,
  PercentageOutlined,
} from '@ant-design/icons';
import { ReactNode } from 'react';

interface menuItemProps {
  title: string;
  icon?: ReactNode;
  url?: any;
  children?: menuItemProps[];
  scope?: any;
}

const menuItems: menuItemProps[] = [
  {
    title: 'Dashboard',
    icon: <DashboardOutlined />,
    url: '/',
  },
  {
    title: 'Orders',
    icon: <DatabaseOutlined />,
    children: [
      {
        title: 'All Orders',
        url: '/orders',
      },
      {
        title: 'Cancellation Requests',
        url: '/order-cancellation-requests',
      },
    ],
  },
  {
    title: 'Products',
    icon: <BarcodeOutlined />,
    children: [
      {
        title: 'All Products',
        url: '/products',
      },
      {
        title: 'Groups',
        url: '/product-groups',
      },
      {
        title: 'Category',
        url: '/category',
      },
    ],
  },
  {
    title: 'Stores',
    icon: <ShopOutlined />,
    children: [
      {
        title: 'All Stores',
        url: '/stores',
      },
      {
        title: 'Pos Users',
        url: '/pos-users',
      },
      {
        title: 'Pos Roles',
        url: '/pos-roles',
      },

      // {
      //   title: 'Pos Active Device',
      //   url: '/pos-active-device',
      // },
    ],
  },
  {
    title: 'Tags',
    icon: <TagOutlined />,
    url: '/tags',
  },
  {
    title: 'Customers',
    icon: <TeamOutlined />,
    url: '/customers',
  },
  {
    title: 'Stock Audits',
    icon: <AuditOutlined />,
    url: '/stock-audits',
  },

  {
    title: 'Stock Validation',
    icon: <ForkOutlined />,

    children: [
      {
        title: 'Primary ',
        url: '/stock-validation',
      },
      {
        title: 'Secondary ',
        url: '/secondary-stock-validation',
      },
    ],
  },

  {
    title: 'Agent',
    icon: <TeamOutlined />,
    url: '/agents',
  },

  {
    title: 'Rule Engine',
    icon: <TagsOutlined />,
    children: [
      {
        title: 'Rules',
        url: '/rules',
      },
      {
        title: 'Coupons',
        url: '/coupons',
      },
    ],
  },
  {
    title: 'Importer Actions',
    icon: <FileExcelOutlined />,
    url: '/importers',
  },

  {
    title: 'App Components',
    icon: <MobileOutlined />,
    url: '/app-components',
  },
  {
    title: 'Settings',
    icon: <SettingOutlined />,

    children: [
      {
        title: 'Task Approvals',
        url: '/task-approvals',
      },
      {
        title: 'Chain Profile',
        url: '/chain-profile',
      },
      {
        title: 'Roles',
        url: '/roles',
      },
      {
        title: 'Users',
        url: '/users',
      },
      {
        title: 'Cancellations',
        url: '/cancellation-reasons',
      },
    ],
  },
  {
    title: 'Stories',
    icon: <NotificationOutlined />,
    url: '/stories',
  },
  {
    title: 'Offers & Promotions',
    icon: <PercentageOutlined />,
    children: [
      {
        title: 'Recommendations',
        url: 'recommendations',
      },
      {
        title: 'Banners',
        url: 'banners',
      },
    ],
  },
  {
    title: 'Feedbacks',
    icon: <EditFilled />,
    url: '/Feedbacks',
  },
  {
    title: 'Downloads',
    icon: <FileMarkdownOutlined />,
    url: '/reports',
  },

  {
    title: 'Payments',
    icon: <WalletOutlined />,
    children: [
      {
        title: 'Transactions',
        url: '/transactions',
      },
      {
        title: 'Refund Transactions',
        url: '/refund-transactions',
      },
      {
        title: 'QR Codes',
        url: '/qr-codes',
      },
      {
        title: 'Disputes',
        url: '/disputes',
      },
      {
        title: 'Downtime',
        url: '/downtime',
      },
    ],
  },
];

export default menuItems;

import axios from './axios';
import config from './config';

const PAYMENTAPI = {
  getTransactions: (params: any) =>
    axios({
      url: `${config.endpoint.transactions}`,
      method: 'GET',
      params,
    }),
  getTransactionsForQR: (params: any, qParams: any) =>
    axios({
      url: `${config.endpoint.transactions}?qr_id=${qParams}`,
      method: 'GET',
      params,
    }),
  getRefundTransactions: (params: any) =>
    axios({
      url: `${config.endpoint.refundTransactions}`,
      method: 'GET',
      params,
    }),
  getDisputes: (params: any) =>
    axios({
      url: `${config.endpoint.disputes}`,
      method: 'GET',
      params,
    }),
  getDisputeDetails: (disputeId: string | undefined) =>
    axios({
      url: `${config.endpoint.disputes}/${disputeId}`,
      method: 'GET',
    }),
  getTransactionsDetails: (transactionsId: string | undefined) =>
    axios({
      url: `${config.endpoint.transactions}/${transactionsId}`,
      method: 'GET',
    }),

  acceptDispute: (disputeId: string, data: any) =>
    axios({
      url: `${config.endpoint.acceptDisputes}/${disputeId}`,
      method: 'POST',
      data,
    }),

  disputeEvidence: (data: any) =>
    axios({
      url: `${config.endpoint.disputeEvidence}`,
      method: 'POST',
      data,
    }),

  contestDispute: (disputeId: string, data: any) =>
    axios({
      url: `${config.endpoint.contestDisputes}/${disputeId}`,
      method: 'POST',
      data,
    }),

  getRefundTransactionsDetails: (transactionsId: string | undefined) =>
    axios({
      url: `${config.endpoint.refundTransactions}/${transactionsId}`,
      method: 'GET',
    }),
  getUpdatePaymentStatus: (
    transactionsId: string | undefined,
    recheck: boolean,
    entity: string
  ) =>
    axios({
      url: `${config.endpoint.getUpdatePaymentStatus}/${transactionsId}/${recheck}/${entity}`,
      method: 'GET',
    }),
  getDowntimes: (driverId: string) =>
    axios({
      url: `${config.endpoint.downtimes}/${driverId}`,
      method: 'GET',
    }),
  getTransactionFromPaymentId: (paymentId: string, driverId: string) =>
    axios({
      url: `${config.endpoint.transactionByPayment}/${paymentId}/${driverId}`,
      method: 'GET',
    }),
  downloadDocumentById: (documentId: string, driverId: string) =>
    axios({
      url: `${config.endpoint.downloadDocumentById}/${documentId}/${driverId}`,
      method: 'GET',
    }),
  getQrCodes: (params: any) =>
    axios({
      url: `${config.endpoint.qrCodes}`,
      method: 'GET',
      params,
    }),
  createQrCode: (form: any) =>
    axios({
      url: `${config.endpoint.qrCodes}/create`,
      method: 'POST',
      data: form,
    }),
  getQRDetails: (qrId: string | undefined) =>
    axios({
      url: `${config.endpoint.qrCodes}/${qrId}`,
      method: 'GET',
    }),
  getQrCodesForStore: (params: any) =>
    axios({
      url: `${config.endpoint.qrCodesForStore}`,
      method: 'GET',
      params,
    }),
  closeQRCode: (qrId: string | undefined) =>
    axios({
      url: `${config.endpoint.closeQR}/${qrId}`,
      method: 'GET',
    }),
  generatePaymentLink: (form: any) =>
    axios({
      url: `${config.endpoint.paymentLink}/create`,
      method: 'POST',
      data: form,
    }),
  cancelPaymentLink: (transactionId: string) =>
    axios({
      url: `${config.endpoint.paymentLink}/cancel/${transactionId}`,
      method: 'PUT',
    }),
  resendPaymentLink: (data: any) =>
    axios({
      url: `${config.endpoint.paymentLink}/resend`,
      method: 'POST',
      data,
    }),
  getPaymentLinkStatus: (transactionId: string) =>
    axios({
      url: `${config.endpoint.paymentLink}/status/${transactionId}`,
      method: 'GET',
    }),
};
export default PAYMENTAPI;

/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-unreachable-loop */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable prefer-const */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Table,
  DatePicker,
  Input,
  Select,
  Space,
  Row,
  Button,
  Col,
} from 'antd';
import styled from 'styled-components';
import { TableProps } from 'antd/lib/table/Table';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  createUrlParams,
  formatArray,
  generateFilterString,
  setFilters,
  getlocalStorage,
  setLocalStorage,
} from '../../util';
import Filters from './Filters';

interface CustomTableProps {
  defaultProps?: Partial<TableProps<any>>;
  columns: any;
  page?: number;
  total?: number;
  showPagination?: boolean;
  assignedKeys?: any;
  getData?: (params: any) => void;
  onChange?: (pagination: any, sorter: any) => void;
}
interface TableState {
  searchHeaders: any;
  params: {
    page: number;
    ordering: any;
    limit: number;
    filters: string;
  };
  identifier?: string;
  isParamSuitable: boolean;
  url: string;
}

const CardTableWrapper = styled.div`
  margin-top: 20px;
  z-index: -1;
  width: 100%;
  overflow: scroll;
  @keyframes fadeRow {
    from {
      background-color: rgba(224, 248, 232, 1);
    }
    to {
      background-color: transparent;
    }
  }

  .ant-table {
    div.ant-table-container {
      width: 100%;
      overflow: auto;
    }
    table-layout: auto;
    .ant-table-thead {
      > tr > th {
        font-weight: 500;
        border-bottom: none;
        text-transform: capitalize !important;
        padding: 15px;
        white-space: nowrap;
      }
    }
    .ant-table-tbody {
      > tr > td {
        font-size: 12px;
        white-space: word-break;
      }
      > tr.fade-out {
        animation: 3s fadeRow forwards;
      }
    }
  }
`;
class Cardtable extends React.Component<CustomTableProps, TableState> {
  constructor(props: any) {
    let addition = props.identifier || '';
    if (props.isParamSuitable === undefined || props.isParamSuitable) {
      setFilters(addition);
    }
    const curUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    let APPLIED_PARAMS = getlocalStorage(`params_${curUrl}${addition}`);

    const APPLIED_HEADERS = getlocalStorage(`headers_${curUrl}${addition}`);

    super(props);
    this.state = {
      searchHeaders: APPLIED_HEADERS || {},
      params:
        this.props.page && APPLIED_PARAMS
          ? { ...APPLIED_PARAMS, page: this.props.page }
          : APPLIED_PARAMS || {
              page: 1,
              ordering: null,
              filters: '',
              limit: 20,
            },
      identifier: props.identifier || '',
      url: curUrl,
      isParamSuitable:
        props.isParamSuitable === undefined ? true : props.isParamSuitable,
    };
  }

  componentDidMount() {
    const { getData } = this.props;
    const { params } = this.state;
    if (getData) getData(params);
    if (this.state.isParamSuitable) {
      const newurl = `${this.state.url}${createUrlParams(
        params.filters,
        params.page
      )}`;
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      window.history.pushState('', '', this.state.url);
    }
  }

  handleChange = (pagination: any, filters: any, sorter: any) => {
    const { getData, onChange } = this.props;
    let { ordering }: any = this.state.params;
    let { searchHeaders } = this.state;
    if (sorter && sorter.order) {
      ordering = sorter.order === 'descend' ? '-' : '';
      ordering += sorter.field;
    } else ordering = null;

    let params = {
      page: pagination.current,
      limit: pagination.pageSize,
      ordering,
      filters: generateFilterString(searchHeaders),
    };

    setLocalStorage(`params_${this.state.url}${this.state.identifier}`, params);

    this.setState({ params }, () => {
      if (getData) getData(params);
    });
    if (onChange) onChange(pagination, sorter);
    if (this.state.isParamSuitable) {
      const newurl = `${this.state.url}${createUrlParams(
        params.filters,
        params.page
      )}`;
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      window.history.pushState('', '', this.state.url);
    }
  };

  onSearch = (searchHeaders: any) => {
    const { getData } = this.props;
    const params = {
      ...this.state.params,
      page: 1,
      filters: generateFilterString(searchHeaders),
    };
    this.setState({ params });
    setLocalStorage(`params_${this.state.url}${this.state.identifier}`, params);
    if (getData) getData(params);
    if (this.state.isParamSuitable) {
      const newurl = `${this.state.url}${createUrlParams(
        params.filters,
        params.page
      )}`;
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      window.history.pushState('', '', this.state.url);
    }
  };

  handleSearch = (selectedKeys: any, dataIndex: any, type: string) => {
    if (selectedKeys.length > 0) {
      let eqType = 'like';
      switch (type) {
        case 'date': {
          eqType = 'date';
          break;
        }
        case 'input_id': {
          eqType = 'equal';
          break;
        }
        case 'input_mobile': {
          eqType = 'equal';
          break;
        }
        case 'input': {
          eqType = 'like';
          break;
        }
        case 'select': {
          eqType = 'equal';
          break;
        }
        default: {
          eqType = 'like';
          break;
        }
      }
      this.setState(prevState => ({
        searchHeaders: {
          ...prevState.searchHeaders,
          [dataIndex]:
            selectedKeys[0] && Array.isArray(selectedKeys[0])
              ? `${eqType}:${moment(selectedKeys[0][0]).format(
                  'YYYY-MM-DD'
                )}~${moment(selectedKeys[0][1]).format('YYYY-MM-DD')}`
              : `${eqType}:${
                  type === 'input_mobile'
                    ? `+91${selectedKeys[0]}`
                    : selectedKeys[0]
                }`,
        },
      }));
      setTimeout(() => {
        let { searchHeaders } = this.state;
        setLocalStorage(
          `headers_${this.state.url}${this.state.identifier}`,
          searchHeaders
        );

        this.onSearch(searchHeaders);
      }, 10);
    }
  };

  handleReset = (dataIndex: string, confirm: any, clearFilters: any) => {
    clearFilters();
    this.setState(prevState => ({
      searchHeaders: {
        ...prevState.searchHeaders,
        [dataIndex]: undefined,
      },
    }));
    setTimeout(() => {
      let { searchHeaders } = this.state;
      setLocalStorage(
        `headers_${this.state.url}${this.state.identifier}`,
        searchHeaders
      );
      this.onSearch(searchHeaders);
    }, 10);
    confirm();
  };

  getColumnSearchProps = (dataIndex: string, type: string, options?: any) => {
    const { searchHeaders } = this.state;
    const selectedOperator =
      searchHeaders[dataIndex] && searchHeaders[dataIndex].split(':')[0];
    let selectedValue =
      searchHeaders[dataIndex] && searchHeaders[dataIndex].split(':')[1];
    if (selectedOperator === 'date') {
      selectedValue =
        searchHeaders[dataIndex] &&
        searchHeaders[dataIndex]
          .split(':')[1]
          .split('~')
          .map((value: any) => moment(value));
    }
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <Space style={{ padding: 10 }} direction="vertical" size="middle">
          {[
            (type === 'input' || type === 'input_id') && (
              <Input
                autoFocus
                key="input"
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0] || selectedValue}
                onChange={e =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => {
                  this.handleSearch(selectedKeys, dataIndex, type);
                }}
              />
            ),
            type === 'input_mobile' && (
              <Input
                autoFocus
                prefix="+91"
                key="input"
                placeholder="Enter 10 digit mobile number"
                value={selectedKeys[0] || selectedValue}
                onChange={e =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => {
                  this.handleSearch(selectedKeys, dataIndex, type);
                }}
                maxLength={10}
              />
            ),
            type === 'select' && (
              <Select
                style={{ width: '100%' }}
                key="select"
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0] || selectedValue}
                onChange={value => {
                  setSelectedKeys(value ? [value] : []);
                }}
                dropdownStyle={{ textTransform: 'capitalize' }}
              >
                {options &&
                  options.map((option: any, index: number) => (
                    <Select.Option key={option.key} value={option.key}>
                      {option.label}
                    </Select.Option>
                  ))}
              </Select>
            ),
            type === 'date' && (
              <DatePicker.RangePicker
                key="date"
                placeholder={['From date', 'To date']}
                value={selectedKeys[0] || selectedValue}
                onChange={e => setSelectedKeys(e ? [e] : [])}
                style={{ marginBottom: 8, display: 'block' }}
              />
            ),
          ]}
          <Row gutter={16}>
            <Col sm={12}>
              <Button
                disabled={!dataIndex}
                onClick={() => {
                  this.handleSearch(selectedKeys, dataIndex, type);
                }}
                type="primary"
                block
              >
                Search
              </Button>
            </Col>
            <Col sm={12}>
              <Button
                disabled={!dataIndex}
                onClick={() =>
                  this.handleReset(dataIndex, confirm, clearFilters)
                }
                type="default"
                block
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Space>
      ),
      filterIcon: (filtered: any) => (
        <SearchOutlined
          style={{ color: searchHeaders[dataIndex] ? '#1890ff' : undefined }}
        />
      ),
    };
  };

  render() {
    const showTotal = (total: number, range: string[]) =>
      `${range[0]}-${range[1]} of ${total} items`;
    const { showPagination, total } = this.props;
    let { columns, assignedKeys } = this.props;
    const { page, limit } = this.state.params;
    if (assignedKeys) {
      columns = columns.map((item: any) =>
        Object.assign(item, {
          search: assignedKeys.find((o: any) => o.key === item.dataIndex),
        })
      );

      columns = columns.map((column: any) =>
        column.showSearch
          ? {
              ...column,
              ...this.getColumnSearchProps(
                column.search && column.search.dataIndex,
                column.type,
                formatArray(column.options)
              ),
            }
          : column
      );
    }

    const paginationProps: any = {
      current: this.props.page ? this.props.page : page,
      total,
      pageSize: limit,
      showTotal,
      defaultPageSize: limit,
      showSizeChanger: true,
      size: 'medium',
    };

    const resetData = (dataIndex: any) => {
      const { getData } = this.props;
      if (dataIndex) {
        this.setState(prevState => ({
          searchHeaders: {
            ...prevState.searchHeaders,
            [dataIndex]: undefined,
          },
        }));
        setTimeout(() => {
          let { searchHeaders } = this.state;
          setLocalStorage(
            `headers_${this.state.url}${this.state.identifier}`,
            searchHeaders
          );
          this.onSearch(searchHeaders);
        }, 10);
      } else {
        this.setState(
          {
            searchHeaders: {},
            params: {
              page: 1,
              ordering: null,
              filters: '',
              limit: 20,
            },
          },
          () => {
            if (getData) getData(this.state.params);
          }
        );
      }
    };
    return (
      <CardTableWrapper>
        <Filters
          getData={resetData}
          identifier={this.state.identifier}
          isParamSuitable={this.state.isParamSuitable}
        />
        <Table
          {...this.props}
          tableLayout="fixed"
          columns={columns}
          onChange={this.handleChange}
          pagination={showPagination ? paginationProps : false}
        />
      </CardTableWrapper>
    );
  }
}

export default Cardtable;

import { combineReducers } from 'redux';
import agentReducer from './agent/agentReducer';
import dataReducer from './app/appReducer';
import orderReducer from './order/orderReducer';
import productReducer from './product/productReducer';
import posReducer from './pos/posReducer';
import storeReducer from './store/storeReducer';
import tagReducer from './tag/tagReducer';
import customerReducer from './customer/customerReducer';
import userReducer from './permissions/userReducer';
import roleReducer from './permissions/roleReducer';
import stockAuditReducer from './stockAudit/stockAuditReducer';
import importerActionsReducer from './importers/importerActionsReducer';
import taskApprovalReducer from './settings/taskApprovalReducer';
import chainsReducer from './settings/chainsReducer';
import cancellationReasonsReducer from './settings/cancellationReasonsReducer';
import couponReducer from './rule/couponReducer';
import ruleReducer from './rule/ruleReducer';
import posRolesReducer from './pos/posrolesreducer';
import reportReducer from './report/reportReducer';
import transactionsReducer from './payment/transactionsReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import appComponentReducer from './appComponent/appComponentReducer';
import syncErrorLogsReducer from './sync-error-log/syncErrorLogReducer';
import posDeviceReducer from './posDevice/posDeviceReducer';
import storiesReducer from './stories/reducer';
import stockValidationReducer from './stock-validation/stockValidationReducer';
import feedbackReducer from './Feedbacks/feedbackReducers';

import recommendationReducer from './recommendations/recommendationReducer';
import bannerReducer from './banner/bannerReducer';

import categoryReducer from './category/categoryReducer';

const reducer = combineReducers({
  data: dataReducer,
  product: productReducer,
  order: orderReducer,
  pos: posReducer,
  posRoles: posRolesReducer,
  store: storeReducer,
  agent: agentReducer,
  tag: tagReducer,
  customer: customerReducer,
  user: userReducer,
  role: roleReducer,
  stockAudit: stockAuditReducer,
  task: taskApprovalReducer,
  importers: importerActionsReducer,
  chains: chainsReducer,
  coupons: couponReducer,
  rules: ruleReducer,
  report: reportReducer,
  transactions: transactionsReducer,
  dashboard: dashboardReducer,
  appComponent: appComponentReducer,
  syncErrorLogs: syncErrorLogsReducer,
  posDevice: posDeviceReducer,
  stories: storiesReducer,
  stockValidation: stockValidationReducer,
  cancellationReasons: cancellationReasonsReducer,
  feedbacks: feedbackReducer,

  recommendation: recommendationReducer,
  banners: bannerReducer,

  category: categoryReducer,
});

export default reducer;

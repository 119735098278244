/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */

export const enum AppActionType {
  LOGIN_USER = 'GET_USERS',
  LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS',
  LOGOUT_USER = 'LOGOUT_USER',
  USERS_ERROR = 'USERS_ERROR',
  VALIDATING_USER = 'VALIDATING_USER',
  USER_VALIDATION_SUCCESS = 'USER_VALIDATION_SUCCESS',
  IS_GOOGLE = 'IS_GOOGLE',
  GOOGLE_LOGIN = 'GOOGLE_LOGIN',
  GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS',
  GOOGLE_LOGIN_FAILURE = 'GOOGLE_LOGIN_FAILURE',
  GENERATE_RESET_TOKEN = 'GENERATE_RESET_TOKEN',
  GENERATE_RESET_TOKEN_SUCCESS = 'GENERATE_RESET_TOKEN_SUCCESS',
  GENERATE_RESET_TOKEN_FAILURE = 'GENERATE_RESET_TOKEN_FAILURE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',
}

export const enum OrderActionType {
  GET_ORDERS = 'GET_ORDERS',
  GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS',
  GET_ORDERS_ERROR = 'GET_ORDERS_ERROR',
  GET_CANCELLATION_REQUESTS = 'GET_CANCELLATION_REQUESTS',
  GET_CANCELLATION_REQUESTS_SUCCESS = 'GET_CANCELLATION_REQUESTS_SUCCESS',
  GET_GET_CANCELLATION_REQUESTS_ERROR = 'GET_GET_CANCELLATION_REQUESTS_ERROR',
  GET_ORDER = 'GET_ORDER',
  GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS',
  GET_ORDER_ERROR = 'GET_ORDER_ERROR',
  CANCEL_ORDER = 'CANCEL_ORDER',
  CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_ERROR = 'CANCEL_ORDER_ERROR',
  EXPORT_ORDER = 'EXPORT_ORDER',
  EXPORT_ORDER_SUCCESS = 'EXPORT_ORDER_SUCCESS',
  EXPORT_ORDER_ERROR = 'EXPORT_ORDER_ERROR',
  RESET_EXPORT = 'RESET_EXPORT',
  EXPORT_CSV = 'EXPORT_CSV',
  EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS',
  EXPORT_CSV_ERROR = 'EXPORT_CSV_ERROR',
  RESET_CSV = 'RESET_CSV',
  FASTFORWARD_TIME = 'FASTFORWARD_TIME',
  FASTFORWARD_TIME_SUCCESS = 'FASTFORWARD_TIME_SUCCESS',
  FASTFORWARD_TIME_FAILED = 'FASTFORWARD_TIME_FAILED',
  GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS',
  INVOICE_DOWNLOAD_SUCCESS = 'INVOICE_DOWNLOAD_SUCCESS',
  INVOICE_DOWNLOAD_FAILED = 'INVOICE_DOWNLOAD_FAILED',
}

export const enum ActivityActionType {
  GET_ACTIVITIES = 'GET_ACTIVITIES',
  GET_ACTIVITIES_SUCCESS = 'GET_ACTIVITIES_SUCCESS',
  GET_ACTIVITIESS_ERROR = 'GET_ACTIVITIESS_ERROR',
}

export const enum ProductActionType {
  GET_PRODUCTS = 'GET_PRODUCTS',
  GET_PRODUCT = 'GET_PRODUCT',

  UPDATING_PRODUCT_INFORMATION = 'UPDATING_PRODUCT_INFORMATION',
  UPDATE_PRODUCT_INFORMATION_SUCCESS = 'UPDATE_PRODUCT_INFORMATION_SUCCESS',

  // Product groups
  GET_PRODUCT_GROUPS = 'GET_PRODUCT_GROUPS',
  GET_PRODUCT_GROUP_DETAILS = 'GET_PRODUCT_GROUP_DETAILS',

  // Store prices
  GETTING_STORE_PRICES = 'GET_PRODUCT_STORES',
  GET_STORE_PRICES_SUCCESS = 'GET_STORE_PRICES_SUCCESS',
  UPDATE_STORE_PRICE = 'UPDATE_STORE_PRICE',
  UPDATE_STORE_PRICE_SUCCESS = 'UPDATE_STORE_PRICE_SUCEESS',

  // Inventory
  GET_PRODUCT_INVENTORY = 'GET_PRODUCT_INVENTORY',
  GET_PRODUCT_INVENTORY_SUCCESS = 'GET_PRODUCT_INVENTORY_SUCCESS',

  LOAD_SUCCESS = 'LOAD_SUCCESS',
  LOAD_FAILED = 'LOAD_FAILED',

  EXPORT_PRODUCT = 'EXPORT_PRODUCT',
  EXPORT_PRODUCT_SUCCESS = 'EXPORT_PRODUCT_SUCCESS',
  EXPORT_PRODUCT_ERROR = 'EXPORT_PRODUCT_ERROR',
  RESET_EXPORT = 'RESET_EXPORT',
  GETTING_TAGS = 'GETTING_TAGS',
  GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS',
  TAGS_LOAD_FAILED = 'TAGS_LOAD_FAILED',

  GET_RULES = 'GET_RULES',
  GET_RULES_SUCCESS = 'GET_RULES_SUCCESS',
  GET_RULES_FAILURE = 'GET_RULES_FAILURE',
}

export const enum StoreActionType {
  GET_STORES = 'GET_STORES',
  GET_STORES_SUCCESS = 'GET_STORES_SUCCESS',
  GET_STORES_ERROR = 'GET_STORES_ERROR',
  GET_STORE = 'GET_STORE',
  GET_STORE_SUCCESS = 'GET_STORE_SUCCESS',
  GET_STORE_ERROR = 'GET_STORE_ERROR',
  CREATE_STORE = 'CREATE_STORE',
  CREATE_STORE_SUCCESS = 'CREATE_STORE_SUCCESS',
  CREATE_STORE_FAILURE = 'CREATE_STORE_FAILURE',
  UPDATE_STORE = 'UPDATE_STORE',
  UPDATE_STORE_SUCCESS = 'UPDATE_STORE_SUCCESS',
  UPDATE_STORE_FAILURE = 'UPDATE_STORE_FAILURE',
  GET_ATTRIBUTES = 'GET_ATTRIBUTES',
  GET_ATTRIBUTES_SUCCESS = 'GET_ATTRIBUTES_SUCCESS',
  GET_ATTRIBUTES_FAILURE = 'GET_ATTRIBUTES_FAILURE',
  UPDATE_ATTRIBUTES = 'UPDATE_ATTRIBUTES',
  UPDATE_ATTRIBUTES_SUCCESS = 'UPDATE_ATTRIBUTES_SUCCESS',
  UPDATE_ATTRIBUTES_FAILURE = 'UPDATE_ATTRIBUTES_FAILURE',
  RESET_STORE = 'RESET_STORE',
  EXPORT_ORDER = 'EXPORT_ORDER',
  EXPORT_ORDER_SUCCESS = 'EXPORT_ORDER_SUCCESS',
  EXPORT_ORDER_ERROR = 'EXPORT_ORDER_ERROR',
  GET_STORE_INVENTORY = 'GET_STORE_INVENTORY',
  GET_STORE_INVENTORY_SUCCESS = 'GET_STORE_INVENTORY_SUCCESS',
  GET_STORE_INVENTORY_SHIPMENT = 'GET_STORE_INVENTORY_SHIPMENT',
  GET_STORE_INVENTORY_SHIPMENT_SUCCESS = 'GET_STORE_INVENTORY_SHIPMENT_SUCCESS',
  VERIFY_INVENTORY_SHIPMENT = 'VERIFY_INVENTORY_SHIPMENT',
  VERIFY_INVENTORY_SHIPMENT_SUCCESS = 'VERIFY_INVENTORY_SHIPMENT_SUCCESS',
  VERIFY_INVENTORY_SHIPMENT_FAILED = 'VERIFY_INVENTORY_SHIPMENT_FAILED',
  VERIFY_RETURN = 'VERIFY_RETURN',
  VERIFY_RETURN_SUCCESS = 'VERIFY_RETURN_SUCCESS',
  VERIFY_RETURN_FAILED = 'VERIFY_RETURN_FAILED',
  GET_STORE_RETURN = 'GET_STORE_RETURN',
  GET_STORE_RETURN_SUCCESS = 'GET_STORE_RETURN_SUCCESS',
  GET_STORE_RETURN_ERROR = 'GET_STORE_RETURN_ERROR',
  RESET_SHIPMENT = 'RESET_SHIPMENT',
  RESET_RETURN = 'RESET_RETURN',
  GET_STOCK_LEDGER = 'GET_STOCK_LEDGER',
  GET_STOCK_LEDGER_SUCCESS = 'GET_STOCK_LEDGER_SUCCESS',
  GET_STOCK_LEDGER_ERROR = 'GET_STOCK_LEDGER_ERROR',
  CLEAR_STOCK_LEDGER = 'CLEAR_STOCK_LEDGER',
  LOAD_FAILED = 'LOAD_FAILED',
  EXPORT_CSV = 'EXPORT_CSV',
  EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS',
  EXPORT_CSV_ERROR = 'EXPORT_CSV_ERROR',
  RESET_EXPORT = 'RESET_EXPORT',
  VERIFY_INVENTORY_SHIPMENT_SUCCESS_FAIL = 'VERIFY_INVENTORY_SHIPMENT_SUCCESS_FAIL',
  VERIFY_RETURN_SUCCESS_FAIL = 'VERIFY_RETURN_SUCCESS_FAIL',
  GET_POS_PAYMENT_LOGS = 'GET_POS_PAYMENT_LOGS',
  GET_POS_PAYMENT_LOGS_SUCCESS = 'GET_POS_PAYMENT_LOGS_SUCCESS',
  GET_POS_PAYMENT_LOGS_ERROR = 'GET_POS_PAYMENT_LOGS_ERROR',
  GET_STORE_LEDGER = 'GET_STORE_LEDGER',
  GET_STORE_LEDGER_SUCCESS = 'GET_STORE_LEDGER_SUCCESS',
  GET_STORE_LEDGER_ERROR = 'GET_STORE_LEDGER_ERROR',
  GET_SALE_SUMMARY = 'GET_SALE_SUMMARY',
  GET_SALE_SUMMARY_SUCCESS = 'GET_SALE_SUMMARY_SUCCESS',
  GET_SALE_SUMMARY_ERROR = 'GET_SALE_SUMMARY_ERROR',
  GET_INCOME_SUMMARY = 'GET_INCOME_SUMMARY',
  GET_INCOME_SUMMARY_SUCCESS = 'GET_INCOME_SUMMARY_SUCCESS',
  GET_INCOME_SUMMARY_ERROR = 'GET_INCOME_SUMMARY_ERROR',
  GET_PAYMENT_INFO_SUCCESS = 'GET_PAYMENT_INFO_SUCCESS',
  GET_PAYMENT_INFO = 'GET_PAYMENT_INFO',
  GET_PAYMENT_INFO_ERROR = 'GET_PAYMENT_INFO_ERROR',
}

export const enum PosActionType {
  GET_ALL_POS_USER = 'GET_ALL_POS_USER',
  GET_ALL_POS_USER_SUCCESS = 'GET_ALL_POS_USER_SUCCESS',
  GET_ALL_POS_USER_FAILURE = 'GET_ALL_POS_USER_FAILURE',
  GET_POS_USER = 'GET_POS_USER',
  GET_POS_USER_SUCCESS = 'GET_POS_USER_SUCCESS',
  GET_POS_USER_FAILURE = 'GET_POS_USER_FAILURE',
  CREATE_POS = 'CREATE_POS',
  CREATE_POS_SUCCESS = 'CREATE_POS_SUCCESS',
  CREATE_POS_FAILURE = 'CREATE_POS_FAILURE',
  UPDATE_POS = 'UPDATE_POS',
  UPDATE_POS_SUCCESS = 'UPDATE_POS_SUCCESS',
  UPDATE_POS_FAILURE = 'UPDATE_POS_FAILURE',
  RESET_POS = 'RESET_POS',
  GET_ALL_POS_ROLES = 'GET_ALL_POS_ROLES',
  GET_ALL_POS_ROLES_SUCCESS = 'GET_ALL_POS_ROLES_SUCCESS',
  GET_ALL_POS_ROLES_FAILURE = 'GET_ALL_POS_ROLES_FAILURE',
  CREATE_POS_ROLE = 'CREATE_POS_ROLE',
  CREATE_POS_ROLE_SUCCESS = 'CREATE_POS_ROLE_SUCCESS',
  CREATE_POS_ROLE_FAILURE = 'CREATE_POS_ROLE_FAILURE',
  GET_POS_ROLES = 'GET_POS_ROLES',
  GET_POS_ROLES_SUCCESS = 'GET_POS_ROLES_SUCCESS',
  GET_POS_ROLES_FAILURE = 'GET_POS_ROLES_FAILURE',
  RESET_POS_AUTH = 'RESET_POS_AUTH',
  RESET_POS_AUTH_SUCCESS = 'RESET_POS_AUTH_SUCCESS',
  RESET_POS_AUTH_FAILURE = 'RESET_POS_AUTH_FAILURE',

  GET_POS_PERMISSIONS = 'GET_POS_PERMISSIONS',
  GET_POS_PERMISSIONS_SUCCESS = 'GET_POS_PERMISSIONS_SUCCESS',

  // Getting a single role
  GET_POS_ROLE = 'GET_ROLE',
  GET_POS_ROLE_SUCCESS = 'GET_POS_ROLE_SUCCESS',

  // Update role
  UPDATE_POS_ROLE = 'UPDATE_POS_ROLE',
  UPDATE_POS_ROLE_SUCCESS = 'UPDATE_POS_ROLE_SUCCESS',

  LOAD_FAILED = 'LOAD_FAILED',
}

export const enum AgentActionType {
  GET_AGENTS = 'GET_AGENTS',
  GET_AGENT = 'GET_AGENT',

  GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS',
  GET_AGENT_SUCCESS = 'GET_AGENT_SUCCESS',
  LOAD_FAILED = 'LOAD_FAILED',

  UPDATING_AGENT_INFORMATION = 'UPDATING_AGENT_INFORMATION',
  UPDATE_AGENT_INFORMATION_SUCCESS = 'UPDATE_AGENT_INFORMATION_SUCCESS',

  CREATING_AGENT_INFORMATION = 'CREATING_AGENT_INFORMATION',
  CREATE_AGENT_INFORMATION_SUCCESS = 'CREATE_AGENT_INFORMATION_SUCCESS',
  CREATE_LOAD_FAILED = 'CREATE_LOAD_FAILED',
  GET_CATEGORY = 'GET_CATEGORY',
}

export const enum PosDeviceActionType {
  GET_POS_ACTIVE_DEVICE = 'GET_POS_ACTIVE_DEVICE',
  GET_POS_ACTIVE_DEVICE_SUCCESS = 'GET_POS_ACTIVE_DEVICE_SUCCESS',
  GET_POS_ACTIVE_DEVICE_FAILURE = 'GET_POS_ACTIVE_DEVICE_FAILURE',
  GET_POS_DEVICE_CHART = 'GET_POS_DEVICE_CHART',
  GET_POS_DEVICE_CHART_SUCCESS = 'GET_POS_DEVICE_CHART_SUCCESS',
  GET_POS_DEVICE_CHART_FAILURE = 'GET_POS_DEVICE_CHART_FAILURE',
}

export const enum TagActionType {
  GET_TAGS = 'GET_TAGS',
  GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS',

  // Get assigned tag values
  GET_ASSIGNED_TAG_VALUES = 'GET_ASSIGNED_TAG_VALUES',
  GET_ASSIGNED_TAG_VALUES_SUCCESS = 'GET_ASSIGNED_TAG_VALUES_SUCCESS',

  // Get tag value
  GET_TAG = 'GET_TAG',
  GET_TAG_SUCCESS = 'GET_TAG_SUCCESS',

  // Creating a new tag
  CREATE_TAG = 'CREATE_TAG',
  CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS',

  // Update tag
  UPDATE_TAG = 'UPDATE_TAG',
  UPDATE_TAG_SUCCESS = 'UPDATE_TAG',

  // Assigning a tag
  ASSIGN_TAG = 'ASSIGN_TAG',
  ASSIGN_TAG_SUCCESS = 'ASSIGN_TAG_SUCCESS',
  ASSIGN_TAG_FAIL = 'ASSIGN_TAG_FAIL',

  // Unassign a tag
  UNASSIGN_TAG = 'UNASSIGN_TAG',
  UNASSIGN_TAG_SUCCESS = 'UNASSIGN_TAG_SUCCESS',
  UNASSIGN_TAG_FAIL = 'UNASSIGN_TAG_FAIL',

  LOAD_FAILED = 'LOAD_FAILED',

  // Allowed fields for tag type
  SEARCH_TAG_FIELDS = 'SEARCH_TAG_FIELDS',
  SEARCH_TAG_FIELDS_FAILURE = 'SEARCH_TAG_FIELDS_FAILURE',
  SEARCH_TAG_FIELDS_SUCCESS = 'SEARCH_TAG_FIELDS_SUCCESS',

  // Search tag related items
  SEARCH_RELATED_ITEMS = 'SEARCH_ASSIGNED_PRODUCTS',
  SEARCH_RELATED_ITEMS_SUCCESS = 'SEARCH_RELATED_ITEMS_SUCCESS',
  SEARCH_RELATED_ITEMS_FAILURE = 'SEARCH_RELATED_ITEMS_FAILURE',

  // Assign multiple items to a tag
  ASSIGN_ITEM = 'ASSIGN_ITEM',
  ASSIGN_ITEM_SUCCESS = 'ASSIGN_ITEM_SUCCESS',
  ASSIGN_ITEM_FAILURE = 'ASSIGN_ITEM_FAILURE',
}

export const enum CustomerActionType {
  START_FETCH_OVERALL_STATS = 'START_FETCH_OVERALL_STATS',
  FINISH_FETCH_OVERALL_STATS = 'FINISH_FETCH_OVERALL_STATS',

  START_FETCH_ALL_CUSTOMERS = 'START_FETCH_ALL_CUSTOMERS',
  FINISH_FETCH_ALL_CUSTOMERS = 'FINISH_FETCH_ALL_CUSTOMERS',

  START_FETCH_ACCOUNT_INFO = 'START_FETCH_ACCOUNT_INFO',
  FINISH_FETCH_ACCOUNT_INFO = 'FINISH_FETCH_ACCOUNT_INFO',

  START_UPDATE_ACCOUNT_INFO = 'START_UPDATE_ACCOUNT_INFO',
  FINISH_UPDATE_ACCOUNT_INFO = 'FINISH_UPDATE_ACCOUNT_INFO',

  START_FETCH_LOYALTY_POINTS = 'START_FETCH_LOYALTY_POINTS',
  FINISH_FETCH_LOYALTY_POINTS = 'FINISH_FETCH_LOYALTY_POINTS',

  START_FETCH_MONTHLY_LOYALTY_POINTS = 'START_FETCH_MONTHLY_LOYALTY_POINTS',
  FINISH_FETCH_MONTHLY_LOYALTY_POINTS = 'FINISH_FETCH_MONTHLY_LOYALTY_POINTS',

  START_FETCH_ALL_ORDERS = 'START_FETCH_ALL_ORDERS',
  FINISH_FETCH_ALL_ORDERS = 'FINISH_FETCH_ALL_ORDERS',

  START_FETCH_USED_COUPONS = 'START_FETCH_USED_COUPONS',
  FINISH_FETCH_USED_COUPONS = 'FINISH_FETCH_USED_COUPONS',

  START_FETCH_ASSOCIATED_DEVICES = 'START_FETCH_ASSOCIATED_DEVICES',
  FINISH_FETCH_ASSOCIATED_DEVICES = 'FINISH_FETCH_ASSOCIATED_DEVICES',

  START_FETCH_ASSOCIATED_TAGS = 'START_FETCH_ASSOCIATED_TAGS',
  FINISH_FETCH_ASSOCIATED_TAGS = 'FINISH_FETCH_ASSOCIATED_TAGS',

  START_FETCH_ALL_CARTS = 'START_FETCH_ALL_CARTS',
  FINISH_FETCH_ALL_CARTS = 'FINISH_FETCH_ALL_CARTS',

  START_FETCH_ASSOCIATED_STORES = 'START_FETCH_ASSOCIATED_STORES',
  FINISH_FETCH_ASSOCIATED_STORES = 'FINISH_FETCH_ASSOCIATED_STORES',

  START_FETCH_SUPPORT_TICKETS = 'START_FETCH_SUPPORT_TICKETS',
  FINISH_FETCH_SUPPORT_TICKETS = 'FINISH_FETCH_SUPPORT_TICKETS',

  START_FETCH_CUSTOMER_ADDRESS = 'START_FETCH_CUSTOMER_ADDRESS',
  FINISH_FETCH_CUSTOMER_ADDRESS = 'FINISH_FETCH_CUSTOMER_ADDRESS',

  EXPORT_LOYALTY_POINTS = 'EXPORT_LOYALTY_POINTS',
  EXPORT_LOYALTY_POINTS_SUCCESS = 'EXPORT_LOYALTY_POINTS_SUCCESS',
  EXPORT_LOYALTY_POINTS_ERROR = 'EXPORT_LOYALTY_POINTS_ERROR',

  LOAD_FAILED = 'LOAD_FAILED',
  GET_CART_DETAILS = 'GET_CART_DETAILS',
  GET_CART_DETAILS_SUCCESS = 'GET_CART_DETAILS_SUCCESS',
  GET_CART_DETAILS_ERROR = 'GET_CART_DETAILS_ERROR',
}

export const enum StoriesActionTypes {
  START_FETCH_STORIES = 'START_FETCH_STORIES',
  FINISH_FETCH_STORIES = 'FINISH_FETCH_STORIES',

  START_FETCH_STORY_DETAILS = 'START_FETCH_STORY_DETAILS',
  FINISH_FETCH_STORY_DETAILS = 'FINISH_FETCH_STORY_DETAILS',

  START_FETCH_STORY_IMAGE = 'START_FETCH_STORY_IMAGE',
  FINISH_FETCH_STORY_IMAGE = 'FINISH_FETCH_STORY_IMAGE',

  START_CREATE_STORY = 'START_CREATE_STORY',
  FINISH_CREATE_STORY = 'FINISH_CREATE_STORY',

  START_UPDATE_STORY = 'START_UPDATE_STORY',
  FINISH_UPDATE_STORY = 'FINISH_UPDATE_STORY',

  START_ADD_IMAGE = 'START_ADD_IMAGE',
  FINISH_ADD_IMAGE = 'FINISH_ADD_IMAGE',

  START_UPDATE_IMAGE = 'START_UPDATE_IMAGE',
  FINISH_UPDATE_IMAGE = 'FINISH_UPDATE_IMAGE',

  LOAD_FAILED = 'LOAD_FAILED',
}

export const enum UserActionType {
  // Getting users
  GET_USERS = 'GET_USERS',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',

  // Creting a user
  CREATE_USER = 'CREATE_USER',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',

  // Getting a single user
  GET_USER = 'GET_USER',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',

  // Update user
  UPDATE_USER = 'UPDATE_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',

  LOAD_FAILED = 'LOAD_FAILED',
}

export const enum RoleActionType {
  // Getting roles
  GET_ROLES = 'GET_ROLES',
  GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS',

  // Getting list of permissions
  GET_PERMISSIONS = 'GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS',

  // Getting list of roles
  GET_ALL_ROLES = 'GET_ALL_ROLES',
  GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS',

  // Creting a role
  CREATE_ROLE = 'CREATE_ROLE',
  CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS',

  // Getting a single role
  GET_ROLE = 'GET_ROLE',
  GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS',

  // Update role
  UPDATE_ROLE = 'UPDATE_ROLE',
  UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS',

  LOAD_FAILED = 'LOAD_FAILED',
}

export const enum StockAuditActionType {
  START_FETCH_ALL_STOCK_AUDITS = 'START_FETCH_ALL_STOCK_AUDITS',
  FINISH_FETCH_ALL_STOCK_AUDITS = 'FINISH_FETCH_ALL_STOCK_AUDITS',

  START_FETCH_STOCK_AUDIT_ITEMS = 'START_FETCH_STOCK_AUDIT_ITEMS',
  FINISH_FETCH_STOCK_AUDIT_ITEMS = 'FINISH_FETCH_STOCK_AUDIT_ITEMS',

  UPDATE_STATUS = 'UPDATE_STOCK_STATUS',
  UPDATE_STATUS_SUCCESS = 'UPDATE_STOCK_STATUS_SUCCESS',
  UPDATE_STATUS_FAILURE = 'UPDATE_STOCK_STATUS_FAILURE',

  LOAD_FAILED = 'LOAD_FAILED',
  EXPORT_PRODUCT = 'EXPORT_PRODUCT',
  EXPORT_STOCK_AUDIT = 'EXPORT_STOCK_AUDIT',
  EXPORT_STOCK_AUDIT_SUCCESS = 'EXPORT_STOCK_AUDIT_SUCCESS',
  EXPORT_STOCK_AUDIT_ERROR = 'EXPORT_STOCK_AUDIT_ERROR',
  RESET_EXPORT = 'RESET_EXPORT',
  CREATE_STOCK_AUDIT = 'CREATE_STOCK_AUDIT',
  CREATE_STOCK_AUDIT_SUCCESS = 'CREATE_STOCK_AUDIT_SUCCESS',
  CREATE_STOCK_AUDIT_ERROR = 'CREATE_STOCK_AUDIT_ERROR',
  UPDATE_STOCK_AUDIT = 'UPDATE_STOCK_AUDIT',
  UPDATE_STOCK_AUDIT_SUCCESS = 'UPDATE_STOCK_AUDIT_SUCCESS',
  UPDATE_STOCK_AUDIT_ERROR = 'UPDATE_STOCK_AUDIT_ERROR',
}

export const enum TaskApprovalActionType {
  // Get task approval grid
  GET_TASK_APPROVALS = 'GET_TASK_APPROVALS',
  GET_TASK_APPROVALS_SUCCESS = 'GET_TASK_APPROVALS_SUCCESS',

  // Update status
  UPDATE_STATUS = 'UPDATE_STATUS',
  UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS',

  LOAD_FAILED = 'LOAD_FAILED',
}
export const enum ChainsActionType {
  GET_CHAINS = 'GET_CHAINS',

  LOAD_SUCCESS = 'LOAD_SUCCESS',
  LOAD_FAILED = 'LOAD_FAILED',

  UPDATING_CHAINS_INFORMATION = 'UPDATING_CHAINS_INFORMATION',
  UPDATE_CHAINS_INFORMATION_SUCCESS = 'UPDATE_CHAINS_INFORMATION_SUCCESS',
}

export const enum ImporterActionsTypes {
  START_FETCH_ALL_IMPORTERS = 'START_FETCH_ALL_IMPORTERS',
  FINISH_FETCH_ALL_IMPORTERS = 'FINISH_FETCH_ALL_IMPORTERS',

  START_ADD_NEW_IMPORT = 'START_ADD_NEW_IMPORT',
  FINISH_ADD_NEW_IMPORT = 'FINISH_ADD_NEW_IMPORT',

  LOAD_FAILED = 'LOAD_FAILED',
}

export const enum RuleActionTypes {
  GET_RULE = 'GET_RULE',
  GET_RULE_SUCCESS = 'GET_RULE_SUCCESS',
  GET_RULE_FAILURE = 'GET_RULE_FAILURE',

  // Getting rules grid
  GET_RULES = 'GET_RULES',
  GET_RULES_SUCCESS = 'GET_RULES_SUCCESS',

  // Exporting rules into csv
  EXPORT_CSV = 'EXPORT_CSV',
  EXPORT_CSV_SUCCESS = 'EXPORT_CSV_SUCCESS',
  EXPORT_CSV_FAILURE = 'EXPORT_CSV_FAILURE',

  // Getting coupons grid

  GET_COUPONS = 'GET_COUPONS',
  GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS',

  GET_RULE_COUPON = 'GET_RULE_COUPON',
  GET_RULE_COUPON_SUCCESS = 'GET_RULE_COUPON_SUCCESS',

  // Exporting coupons into csv
  EXPORT_COUPONS_CSV = 'EXPORT_COUPONS_CSV',
  EXPORT_COUPONS_CSV_SUCCESS = 'EXPORT_COUPONS_CSV_SUCCESS',
  EXPORT_COUPONS_CSV_FAILURE = 'EXPORT_COUPONS_CSV_FAILURE',

  // Exporting coupon into csv
  EXPORT_COUPON_CSV = 'EXPORT_COUPON_CSV',
  EXPORT_COUPON_CSV_SUCCESS = 'EXPORT_COUPON_CSV_SUCCESS',
  EXPORT_COUPON_CSV_FAILURE = 'EXPORT_COUPON_CSV_FAILURE',

  // Updating coupon status
  UPDATE_COUPON = 'UPDATE_COUPON',
  UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS',

  UPDATE_RULE_COUPON = 'UPDATE_RULE_COUPON',
  UPDATE_RULE_COUPON_SUCCESS = 'UPDATE_RULE_COUPON_SUCCESS',
  LOAD_FAILED = 'LOAD_FAILED',

  CREATE_RULE = 'CREATE_RULE',
  CREATE_RULE_SUCCESS = 'CREATE_RULE_SUCCESS',
  CREATE_RULE_FAILED = 'CREATE_RULE_FAILED',

  UPDATE_RULE = 'UPDATE_RULE',
  UPDATE_RULE_SUCCESS = 'UPDATE_RULE_SUCCESS',
  UPDATE_RULE_FAILED = 'UPDATE_RULE_FAILED',

  RESET_RULE = 'RESET_RULE',
  RESET_COUPON = 'RESET_COUPON',
  RESET_EXPORT = 'RESET_EXPORT',
  RESET_COUPON_EXPORT = 'RESET_COUPON_EXPORT',
  RESET_COUPONS_EXPORT = 'RESET_COUPONS_EXPORT',
}
export const enum ReportActionTypes {
  GET_ALL_REPORTS = 'GET_ALL_REPORTS',
  GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS',

  LOAD_FAILED = 'LOAD_FAILED',
}
export const enum PaymentActionType {
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_TRANSACTIONS_DETAILS = 'GET_TRANSACTIONS_DETAILS',

  TRANSACTIONS_SUCCESS = 'TRANSACTIONS_SUCCESS',
  TRANSACTIONS_FAILED = 'TRANSACTIONS_FAILED',

  GET_REFUND_TRANSACTIONS = 'GET_REFUND_TRANSACTIONS',
  REFUND_TRANSACTIONS_SUCCESS = 'REFUND_TRANSACTIONS_SUCCESS',
  REFUND_TRANSACTIONS_FAILED = 'REFUND_TRANSACTIONS_FAILED',
  TRANSACTIONS_DETAIL_SUCCESS = 'TRANSACTIONS_DETAIL_SUCCESS',
  TRANSACTIONS_DETAIL_FAILED = 'TRANSACTIONS_DETAIL_FAILED',
  GET_REFUND_TRANSACTIONS_DETAILS = 'GET_REFUND_TRANSACTIONS_DETAILS',
  REFUND_TRANSACTIONS_DETAIL_SUCCESS = 'REFUND_TRANSACTIONS_DETAIL_SUCCESS',
  REFUND_TRANSACTIONS_DETAIL_FAILED = 'REFUND_TRANSACTIONS_DETAIL_FAILED',
  GET_UPDATE_PAYMENT_STATUS = 'GET_UPDATE_PAYMENT_STATUS',
  GET_UPDATE_PAYMENT_STATUS_SUCCESS = 'GET_UPDATE_PAYMENT_STATUS_SUCCESS',
  GET_UPDATE_PAYMENT_STATUS_FAILED = 'GET_UPDATE_PAYMENT_STATUS_FAILED',

  // Downtimes
  GET_DOWNTIMES = 'GET_DOWNTIMES',
  GET_DOWNTIMES_SUCCESS = 'GET_DOWNTIMES_SUCCESS',
  GET_DOWNTIMES_FAILED = 'GET_DOWNTIMES_FAILED',

  RESET_PAYMENT_STATUS = 'RESET_PAYMENT_STATUS',

  GET_DISPUTES = 'GET_DISPUTES',
  DISPUTES_SUCCESS = 'DISPUTES_SUCCESS',
  DISPUTES_FAILED = 'DISPUTES_FAILED',

  GET_DISPUTES_DETAILS = 'GET_DISPUTES_DETAILS',
  GET_DISPUTES_DETAILS_SUCCESS = 'GET_DISPUTES_DETAILS_SUCCESS',
  GET_DISPUTES_DETAILS_FAILED = 'GET_DISPUTES_DETAILS_FAILED',

  UPLOAD_DISPUTES_DOCUMENT = 'UPLOAD_DISPUTES_DOCUMENT',
  UPLOAD_DISPUTES_DOCUMENT_SUCCESS = 'UPLOAD_DISPUTES_DOCUMENT_SUCCESS',
  UPLOAD_DISPUTES_DOCUMENT_FAILED = 'UPLOAD_DISPUTES_DOCUMENT_FAILED',

  ACCEPT_DISPUTE = 'ACCEPT_DISPUTE',
  ACCEPT_DISPUTE_SUCCESS = 'ACCEPT_DISPUTE_SUCCESS',
  ACCEPT_DISPUTE_FAILED = 'ACCEPT_DISPUTE_FAILED',

  CONTEST_DISPUTE = 'CONTEST_DISPUTE',
  CONTEST_DISPUTE_SUCCESS = 'CONTEST_DISPUTE_SUCCESS',
  CONTEST_DISPUTE_FAILED = 'CONTEST_DISPUTE_FAILED',

  DISPUTE_EVIDENCE = 'DISPUTE_EVIDENCE',
  DISPUTE_EVIDENCE_SUCCESS = 'DISPUTE_EVIDENCE_SUCCESS',
  DISPUTE_EVIDENCE_FAILED = 'DISPUTE_EVIDENCE_FAILED',

  GET_TRANSACTION_FROM_PAYMENT_ID = 'GET_TRANSACTION_FROM_PAYMENT_ID',
  GET_TRANSACTION_FROM_PAYMENT_ID_SUCCESS = 'GET_TRANSACTION_FROM_PAYMENT_ID_SUCCESS',
  GET_TRANSACTION_FROM_PAYMENT_ID_FAILURE = 'GET_TRANSACTION_FROM_PAYMENT_ID_FAILURE',

  // QR-Codes
  GET_QR_CODES = 'GET_QR_CODES',
  GET_QR_CODES_SUCCESS = 'GET_QR_CODES_SUCCESS',
  GET_QR_CODES_FAILED = 'GET_QR_CODES_FAILED',

  GET_QR_DETAILS = 'GET_QR_DETAILS',
  GET_QR_DETAILS_SUCCESS = 'GET_QR_DETAILS_SUCCESS',
  GET_QR_DETAILS_FAILED = 'GET_QR_DETAILS_FAILED',
  RESET_QR_DETAILS = 'RESET_QR_DETAILS',
}

export const enum DashboardActionType {
  START_FETCH_STORE_STATS = 'START_FETCH_STORE_STATS',
  FINISH_FETCH_STORE_STATS = 'FINISH_FETCH_STORE_STATS',

  START_FETCH_CUSTOMER_STATS = 'START_FETCH_CUSTOMER_STATS',
  FINISH_FETCH_CUSTOMER_STATS = 'FINISH_FETCH_CUSTOMER_STATS',

  START_FETCH_ORDER_STATS = 'START_FETCH_ORDER_STATS',
  FINISH_FETCH_ORDER_STATS = 'FINISH_FETCH_ORDER_STATS',

  START_FETCH_ORDER_GRAPH = 'START_FETCH_ORDER_GRAPH',
  FINISH_FETCH_ORDER_GRAPH = 'FINISH_FETCH_ORDER_GRAPH',
  START_FETCH_STORE_GRAPH_DATA = 'START_FETCH_STORE_GRAPH_DATA',
  FINISH_FETCH_STORE_GRAPH_DATA = 'FINISH_FETCH_STORE_GRAPH_DATA',

  START_FETCH_CUSTOMERS_GRAPH_DATA = 'START_FETCH_CUSTOMERS_GRAPH_DATA',
  FINISH_FETCH_CUSTOMERS_GRAPH_DATA = 'FINISH_FETCH_CUSTOMERS_GRAPH_DATA',

  START_FETCH_ORDER_STATUS_GRAPH_DATA = 'START_FETCH_ORDER_STATUS_GRAPH_DATA',
  FINISH_FETCH_ORDER_STATUS_GRAPH_DATA = 'FINISH_FETCH_ORDER_STATUS_GRAPH_DATA',

  LOAD_FAILED = 'LOAD_FAILED',
  START_FETCH_AVERAGE_ORDER_GRAPH = 'START_FETCH_AVERAGE_ORDER_GRAPH',
  FINISH_FETCH_AVERAGE_ORDER_GRAPH = 'FINISH_FETCH_AVERAGE_ORDER_GRAPH',
}

export const enum AppComponentActionTypes {
  FETCH_ALL_SCREENS = 'FETCH_ALL_SCREENS',
  FETCH_ALL_SCREENS_SUCCESS = 'FETCH_ALL_SCREENS_SUCCESS',
  FETCH_ALL_SCREENS_FAILURE = 'FETCH_ALL_SCREENS_FAILURE',

  FETCH_SCREENS = 'FETCH_SCREENS',
  FETCH_SCREENS_SUCCESS = 'FETCH_SCREENS_SUCCESS',
  FETCH_SCREENS_FAILURE = 'FETCH_SCREENS_FAILURE',

  ADD_NEW_SCREEN = 'ADD_NEW_SCREEN',
  ADD_NEW_SCREEN_SUCCESS = 'ADD_NEW_SCREEN_SUCCESS',
  ADD_NEW_SCREEN_FAILURE = 'ADD_NEW_SCREEN_FAILURE',

  UPDATE_SCREEN = 'UPDATE_SCREEN',
  UPDATE_SCREEN_SUCCESS = 'UPDATE_SCREEN_SUCCESS',
  UPDATE_SCREEN_FAILURE = 'UPDATE_SCREEN_SUCCESS',

  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE',

  FETCH_SCREEN_BY_ID = 'FETCH_SCREEN_BY_ID',
  FETCH_SCREEN_BY_ID_SUCCESS = 'FETCH_SCREEN_BY_ID_SUCCESS',
  FETCH_SCREEN_BY_ID_FAILURE = 'FETCH_SCREEN_BY_ID_FAILURE',

  FETCH_COMPONENTS_LIST = 'FETCH_COMPONENTS_LIST',
  FETCH_COMPONENTS_LIST_SUCCESS = 'FETCH_COMPONENTS_LIST_SUCCESS',
  FETCH_COMPONENTS_LIST_FAILURE = 'FETCH_COMPONENTS_LIST_FAILURE',

  REORDER_COMPONENT = 'REORDER_COMPONENT',
  REORDER_COMPONENT_SUCCESS = 'REORDER_COMPONENT_SUCCESS',
  REORDER_COMPONENT_FAILURE = 'REORDER_COMPONENT_FAILURE',

  REORDER_COMPONENT_UNITS = 'REORDER_COMPONENT_UNITS',
  REORDER_COMPONENT_UNITS_SUCCESS = 'REORDER_COMPONENT_UNITS_SUCCESS',
  REORDER_COMPONENT_UNITS_FAILURE = 'REORDER_COMPONENT_UNITS_FAILURE',

  CREATE_COMPONENT = 'CREATE_COMPONENT',
  CREATE_COMPONENT_SUCCESS = 'CREATE_COMPONENT_SUCCESS',
  CREATE_COMPONENT_FAILURE = 'CREATE_COMPONENT_FAILURE',

  UPDATE_COMPONENT = 'UPDATE_COMPONENT',
  UPDATE_COMPONENT_SUCCESS = 'UPDATE_COMPONENT_SUCCESS',
  UPDATE_COMPONENT_FAILURE = 'UPDATE_COMPONENT_FAILURE',

  FETCH_COMPONENTS_TYPES = 'FETCH_COMPONENTS_TYPES',
  FETCH_COMPONENTS_TYPES_SUCCESS = 'FETCH_COMPONENTS_TYPES_SUCCESS',
  FETCH_COMPONENTS_TYPES_FAILURE = 'FETCH_COMPONENTS_TYPES_FAILURE',

  ADD_DATA_TO_COMPONENTS_LIST = 'ADD_DATA_TO_COMPONENTS_LIST',
  ADD_DATA_TO_COMPONENTS_LIST_SUCCESS = 'ADD_DATA_TO_COMPONENTS_LIST_SUCCESS',
  ADD_DATA_TO_COMPONENTS_LIST_FAILURE = 'ADD_DATA_TO_COMPONENTS_LIST_FAILURE',

  FETCH_COMPONENT_BY_ID = 'FETCH_COMPONENT_BY_ID',
  FETCH_COMPONENT_BY_ID_SUCCESS = 'FETCH_COMPONENT_BY_ID_SUCCESS',
  FETCH_COMPONENT_BY_ID_FAILURE = 'FETCH_COMPONENT_BY_ID_FAILURE',

  DELETE_IMAGE = 'DELETE_IMAGE',
  DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAILURE = 'DELETE_IMAGE_FAILURE',

  UPDATE_IMAGE = 'UPDATE_IMAGE',
  UPDATE_IMAGE_SUCCESS = 'UPDATE_IMAGE_SUCCESS',
  UPDATE_IMAGE_FAILURE = 'UPDATE_IMAGE_FAILURE',

  GET_COMPONENT_ACTIONS = 'GET_COMPONENT_ACTIONS',
  GET_COMPONENT_ACTIONS_SUCCESS = 'GET_COMPONENT_ACTIONS_SUCCESS',
  GET_COMPONENT_ACTIONS_FAILURE = 'GET_COMPONENT_ACTIONS_FAILURE',

  CREATE_COMPONENT_UNIT = 'CREATE_COMPONENT_UNIT',
  CREATE_COMPONENT_UNIT_SUCCESS = 'CREATE_COMPONENT_UNIT_SUCCESS',
  CREATE_COMPONENT_UNIT_FAILURE = 'CREATE_COMPONENT_UNIT_FAILURE',

  UPDATE_COMPONENT_UNIT = 'UPDATE_COMPONENT_UNIT',
  UPDATE_COMPONENT_UNIT_SUCCESS = 'UPDATE_COMPONENT_UNIT_SUCCESS',
  UPDATE_COMPONENT_UNIT_FAILURE = 'UPDATE_COMPONENT_UNIT_FAILURE',

  ADD_FILTER = 'ADD_FILTER',
  ADD_FILTER_SUCCESS = 'ADD_FILTER_SUCCESS',
  ADD_FILTER_FAILURE = 'ADD_FILTER_FAILURE',

  UPDATE_FILTER = 'UPDATE_FILTER',
  UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS',
  UPDATE_FILTER_FAILURE = 'UPDATE_FILTER_FAILURE',

  INVALIDATE = 'INVALIDATE',
  INVALIDATE_SUCCESS = 'INVALIDATE_SUCCESS',
  INVALIDATE_FAILURE = 'INVALIDATE_FAILURE',

  CHANGE_UNIT_COMPONENTS_STATUS = 'CHANGE_UNIT_COMPONENTS_STATUS',

  LOAD_FAILED = 'LOAD_FAILED',
  RESET = 'RESET',
}

export const enum SyncErrorLogActionType {
  GET_SYNC_ERROR_LOGS = 'GET_SYNC_ERROR_LOGS',
  GET_SYNC_ERROR_LOGS_SUCCESS = 'GET_SYNC_ERROR_LOGS_SUCCESS',
  GET_SYNC_ERROR_LOGS_ERROR = 'GET_SYNC_ERROR_LOGS_ERROR',
  RESET_SYNC_ERROR_LOGS = 'RESET_SYNC_ERROR_LOGS',
}

export const enum StockValidationActionType {
  GET_PRODUCT_DATA_FOR_STORE = 'GET_PRODUCT_DATA_FOR_STORE',
  GET_PRODUCT_DATA_SUCCESS = 'GET_PRODUCT_DATA_SUCCESS',
  GET_PRODUCT_DATA_FAILURE = 'GET_PRODUCT_DATA_FAILURE',
  GET_CSV_DATA_FOR_STORE = 'GET_CSV_DATA_FOR_STORE',
  GET_CSV_DATA_SUCCESS = 'GET_CSV_DATA_SUCCESS',
  GET_CSV_DATA_FAILURE = 'GET_CSV_DATA_FAILURE',
  RESET_CSV_LOADER = 'RESET_CSV_LOADER',
  RESET_INPUT = 'RESET_INPUT',
  GET_SEC_STOCK_AUDITS = 'GET_SEC_STOCK_AUDITS',
  GET_SEC_STOCK_VALIDATION = 'GET_SEC_STOCK_VALIDATION',
  GET_SEC_STOCK_VALIDATION_SUCCESS = 'GET_SEC_STOCK_VALIDATION_SUCCESS',
  GET_SEC_STOCK_VALIDATION_FAILURE = 'GET_SEC_STOCK_VALIDATION_FAILURE',
  GET_CSV_DATA_FOR_PRODUCT = 'GET_CSV_DATA_FOR_PRODUCT',
  GET_CSV_DATA_FOR_PRODUCT_SUCCESS = 'GET_CSV_DATA_FOR_PRODUCT_SUCCESS',
  GET_CSV_DATA_FOR_PRODUCT_FAILURE = 'GET_CSV_DATA_FOR_PRODUCT_FAILURE',
  SEC_RESET_INPUT = 'SEC_RESET_INPUT',
  RESET_CSV_PRODUCT_LOADER = 'RESET_CSV_PRODUCT_LOADER',
}
export const enum FeedbacksActionType {
  GET_FEEDBACKS = 'GET_FEEDBACKS',
  GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS',
  GET_FEEDBACKS_FAILURE = 'GET_FEEDBACKS_FAILURE ',
  GET_FEEDBACKS_CSV = 'GET_FEEDBACKS_CSV',
  GET_FEEDBACKS_CSV_SUCCESS = 'GET_FEEDBACKS_CSV_SUCCESS',
  GET_FEEDBACKS_CSV_ERROR = 'GET_FEEDBACKS_CSV_ERROR',
  GENERATE_CSV = 'GENERATE_CSV',
  GENERATE_CSV_SUCCESS = 'GENERATE_CSV_SUCCESS',
  GENERATE_CSV_ERROR = 'GENERATE_CSV_ERROR',
  RESET_EXPORT = 'RESET_EXPORT',
}

export const enum CancellationReasonsType {
  GET_CANCELLATION_REASONS = 'GET_CANCELLATION_REASONS',
  GET_CANCELLATION_REASONS_SUCCESS = 'GET_CANCELLATION_REASONS_SUCCESS',
  GET_CANCELLATION_REASONS_FAILURE = 'GET_CANCELLATION_REASONS_FAILURE',

  POST_CANCELLATION_REASON = 'POST_CANCELLATION_REASON',
  POST_CANCELLATION_REASON_SUCCESS = 'POST_CANCELLATION_REASON_SUCCESS',

  UPDATE_CANCELLATION_REASON = 'UPDATE_CANCELLATION_REASON',
  UPDATE_CANCELLATION_REASON_SUCCESS = 'UPDATE_CANCELLATION_REASON_SUCCESS',
}

export const enum RecommendationActionTypes {
  GET_RECOMMENDATION = 'GET_RECOMMENDATION',
  GET_RECOMMENDATION_SUCCESS = 'GET_RECOMMENDATION_SUCCESS',
  GET_RECOMMENDATION_FAILURE = 'GET_RECOMMENDATION_FAILURE',

  CREATE_RECOMMENDATION = 'CREATE_RECOMMENDATION',
  CREATE_RECOMMENDATION_SUCCESS = 'CREATE_RECOMMENDATION_SUCCESS',
  CREATE_RECOMMENDATION_FAILURE = 'CREATE_RECOMMENDATION_FAILURE',

  UPDATE_RECOMMENDATION = 'UPDATE_RECOMMENDATION',
  UPDATE_RECOMMENDATION_SUCCESS = 'UPDATE_RECOMMENDATION_SUCCESS',
  UPDATE_RECOMMENDATION_FAILURE = 'UPDATE_RECOMMENDATION_FAILURE',

  GET_RECOMMENDATION_DETAIL = 'GET_RECOMMENDATION_DETAIL',
  GET_RECOMMENDATION_DETAIL_SUCCESS = 'GET_RECOMMENDATION_DETAIL_SUCCESS',
  GET_RECOMMENDATION_DETAIL_FAILURE = 'GET_RECOMMENDATION_DETAIL_FAILURE',
  RESET_RECOMMENDATION = 'RESET_RECOMMENDATION',
}

export const enum BannerActionTypes {
  GET_BANNERS = 'GET_BANNERS',
  GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS',
  GET_BANNERS_FAILURE = 'GET_BANNERS_FAILURE',

  GET_BANNER_DETAIL = 'GET_BANNER_DETAIL',
  GET_BANNER_DETAIL_SUCCESS = 'GET_BANNER_DETAIL_SUCCESS',
  GET_BANNER_DETAIL_FAILURE = 'GET_BANNER_DETAIL_FAILURE',

  UPDATE_BANNER = 'UPDATE_BANNER',
  UPDATE_BANNER_SUCCESS = 'UPDATE_BANNER_SUCCESS',
  UPDATE_BANNER_FAILURE = 'UPDATE_BANNER_FAILURE',
}
export const enum CategoryActionType {
  GET_CATEGORY = 'GET_CATEGORY',
  GET_CATEGORY_DETAIL = 'GET_CATEGORY_DETAIL',

  GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS',
  GET_CATEGORY_DETAIL_SUCCESS = '  GET_CATEGORY_DETAIL_SUCCESS',
  LOAD_FAILED = 'LOAD_FAILED',
}

import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
  Switch,
  Upload,
} from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BANNERS_API from '../../api/bannersAPI';
import { AsyncSelect, successNotification } from '../../components';

const Createbanner = () => {
  const [fileList, setFileList] = useState<any[]>([]);
  const navigate = useNavigate();
  const props = {
    onRemove: (file: Object) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      const isImage =
        file.type.includes('image/') || file.type.includes('image\\');

      if (isImage) {
        setFileList([file]);
      } else {
        message.error(`${file.name} is not an image file`);
      }

      return false;
    },
    fileList,
  };
  const [form] = Form.useForm();
  const onFinish = async (data: any) => {
    console.log('daaaa', data);
    const storeFilters = [];

    for (let i = 0; i < data?.store_id?.length; i += 1) {
      storeFilters.push({
        filter_type: 'store_id',
        filter_id: data?.store_id[i]?.key,
      });
    }

    for (let i = 0; i < data?.tag_id?.length; i += 1) {
      storeFilters.push({
        filter_type: 'tag_id',
        filter_id: data?.tag_id[i]?.key,
      });
    }

    const formData = new FormData();

    formData.append('name', data.name);
    formData.append(
      'valid_from',
      moment(data.valid_from).format('YYYY-MM-DD HH:mm:ss')
    );
    formData.append(
      'valid_to',
      moment(data.valid_to).format('YYYY-MM-DD HH:mm:ss')
    );
    formData.append('is_active', data.is_active);
    formData.append('image', data.image.file);
    formData.append('store_filters', JSON.stringify(storeFilters));

    BANNERS_API.postBanner(formData).then(res => {
      successNotification({
        message: 'Success!!',
        description: 'Banner created successfully!',
        placement: 'bottomLeft',
      });
      navigate(`/banners`);
    });
  };

  const [showField, setShowField] = useState(false);
  return (
    <Card title="Create Banner">
      <Form form={form} onFinish={onFinish} name="banner" layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter offer name' }]}
        >
          <Input />
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item
              name="image"
              label="Upload Banner Image"
              rules={[{ required: true, message: 'Please enter image' }]}
            >
              <Upload listType="picture" accept="image/*" {...props}>
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="valid_from"
              label="Valid From"
              rules={[{ required: true, message: 'Please enter valid_from' }]}
            >
              <DatePicker
                style={{ width: 300 }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                disabledDate={current =>
                  current && current < moment().subtract(1, 'days')
                }
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="valid_to"
              label="Valid To"
              rules={[{ required: true, message: 'Please enter valid_to' }]}
            >
              <DatePicker
                style={{ width: 300 }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                disabledDate={current =>
                  current && current < moment(form.getFieldValue('valid_from'))
                }
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              style={{ width: 300 }}
              name="is_active"
              label="Status"
              rules={[{ required: true, message: 'Please enter user status' }]}
            >
              <Select>
                <Select.Option value={1}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Form.Item label="Add Store Filter">
            <Switch
              onClick={checked => {
                setShowField(checked);
              }}
            />
          </Form.Item>
        </Row>

        {showField && (
          <>
            <Form.Item name="store_id" label="Store Id">
              <AsyncSelect
                mode="multiple"
                placeholder="Search here"
                url="stores"
                stringlength={0}
              />
            </Form.Item>
            <Form.Item name="tag_id" label="Store Tag">
              <AsyncSelect
                mode="multiple"
                placeholder="Search here"
                url="store-tag"
                stringlength={0}
              />
            </Form.Item>
          </>
        )}

        <Row justify="end">
          <Col sm={24} xs={24}>
            <Button block type="primary" onClick={form.submit} loading={false}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Createbanner;

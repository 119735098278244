import { BannerActionTypes } from '../../actions/ActionTypes';

interface Banners {
  banners: object;
  Loader: boolean;
  banner: object;
}

const initialState: Banners = {
  banners: {},
  Loader: false,
  banner: {},
};

const reducer = (state: Banners = initialState, action: any = {}) => {
  switch (action.type) {
    case BannerActionTypes.GET_BANNERS: {
      return {
        ...state,
        Loader: true,
      };
    }
    case BannerActionTypes.GET_BANNERS_SUCCESS: {
      return {
        ...state,
        banners: action.payload,
        Loader: false,
      };
    }
    case BannerActionTypes.GET_BANNER_DETAIL: {
      return {
        ...state,
        Loader: true,
        error: false,
      };
    }
    case BannerActionTypes.GET_BANNER_DETAIL_SUCCESS: {
      return {
        ...state,

        Loader: false,
        banner: action.payload.data,
      };
    }
    case BannerActionTypes.GET_BANNER_DETAIL_FAILURE: {
      return {
        ...state,

        Loader: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

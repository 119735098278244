import get from 'lodash.get';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Cardtable } from '../../components';
import { formatDate } from '../../util';
import { useAppDispatch, useAppSelector } from '../../redux';
import bannerAction from '../../redux/actions/banners/bannerAction';

const Banners = () => {
  const dispatch = useAppDispatch();
  const { banners } = useAppSelector(state => state.banners);
  const navigate = useNavigate();
  const addBannerHandler = () => {
    navigate('/banners/create');
  };

  const heading = () => (
    <Row justify="space-between">
      <Col>
        <Typography.Text strong>Banners</Typography.Text>{' '}
      </Col>
      <Col>
        <Space size="large">
          <Button
            type="default"
            icon={<PlusOutlined />}
            onClick={addBannerHandler}
          >
            Add Banner
          </Button>
        </Space>
      </Col>
    </Row>
  );
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      showSearch: true,
      type: 'input_id',
      render: (text: string, row: any) => (
        <Link to={`/banners/${row.id}`}>{text}</Link>
      ),
      width: 100,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      showSearch: true,
      type: 'input_id',
      width: 200,
    },
    {
      title: 'Valid From',
      dataIndex: 'valid_from',
      width: 150,
      showSearch: true,
      type: 'date',
      render: (text: string) => (text ? formatDate(text, 'lll') : ''),
    },
    {
      title: 'Valid To',
      dataIndex: 'valid_to',
      showSearch: true,
      type: 'date',
      width: 150,
      render: (text: string) => (text ? formatDate(text, 'lll') : ''),
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      render: (text: 1) => (text === 1 ? 'Active' : 'Inactive'),
      showSearch: true,
      type: 'select',
      options: [
        { id: '1', name: 'Active' },
        { id: '0', name: 'Inactive' },
      ],
      width: 150,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      width: 150,
      type: 'date',
      render: (text: string) => (text ? formatDate(text, 'lll') : ''),
    },

    {
      title: 'Deleted At',
      dataIndex: 'deleted_at',
      type: 'date',
      width: 150,
      render: (text: string) => (text ? formatDate(text, 'lll') : ''),
    },
  ];
  const getData = (params: any) => {
    dispatch(bannerAction.getBanners(params));
  };
  return (
    <Cardtable
      total={get(banners, 'count', 0)}
      getData={getData}
      // @ts-ignore
      title={heading}
      loading={false}
      rowKey="ref_id"
      dataSource={get(banners, 'results', [])}
      assignedKeys={get(banners, 'assignedKeys', [])}
      columns={columns}
      showPagination
    />
  );
};

export default Banners;

import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
  Upload,
} from 'antd';
import Title from 'antd/lib/skeleton/Title';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AsyncSelect,
  errorNotification,
  successNotification,
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux';
import bannerAction from '../../redux/actions/banners/bannerAction';
import { formatArray } from '../../util';

const BannerDetails = () => {
  const { bannerId, Loader } = useParams();
  const { banner } = useAppSelector(state => state.banners);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let assignedStores: any = [];
  let assignedTags: any = [];
  useEffect(() => {
    dispatch(bannerAction.getBannerDetails(`${bannerId}`));
  }, []);
  banner?.store_filters?.forEach((filter: any) => {
    if (filter.filter_type === 'store_id') {
      assignedStores.push(filter.filter_object);
    }
  });
  assignedStores = formatArray(assignedStores);

  banner?.store_filters?.forEach((filter: any) => {
    if (filter.filter_type === 'tag_id') {
      assignedTags.push(filter.filter_object);
    }
  });
  assignedTags = formatArray(assignedTags);
  const onFinish = (data: any) => {
    setLoading(true);
    data.id = banner.id;
    const storeFilters = [];

    for (let i = 0; i < data?.store_id?.length; i += 1) {
      storeFilters.push({
        filter_type: 'store_id',
        filter_id: data?.store_id[i]?.key,
      });
    }

    for (let i = 0; i < data?.tag_id?.length; i += 1) {
      storeFilters.push({
        filter_type: 'tag_id',
        filter_id: data?.tag_id[i]?.key,
      });
    }
    delete data.tag_id;
    delete data.store_id;

    data.store_filters = storeFilters;

    new Promise((resolve, reject) => {
      resolve(dispatch(bannerAction.updateBanner(data)));
      reject();
    })
      .then(() => {
        setLoading(false);
        successNotification({
          message: 'Success!!',
          description: 'Record update successfully!',
          placement: 'bottomLeft',
        });
        navigate(`/banners`);
      })
      .catch(err => {
        setLoading(false);
        errorNotification({
          message: 'Error!',
          description: `${err.message}`,
          placement: 'bottomLeft',
        });
      });
  };
  useEffect(() => {
    form.setFieldsValue({
      name: banner.name,
      valid_from: moment(banner.valid_from),
      valid_to: moment(banner.valid_to),
      is_active: banner.is_active,
      selection_type: banner.selection_type,
      include_out_of_stock: banner.include_out_of_stock,
      image: banner.image_url,
      store_id: assignedStores || [],
      tag_id: assignedTags || [],
    });
  }, [banner]);

  const props = {
    onRemove: (file: Object) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: any) => {
      const isImage =
        file.type.includes('image/') || file.type.includes('image\\');

      if (isImage) {
        setFileList([file]);
      } else {
        message.error(`${file.name} is not an image file`);
      }

      return false;
    },
    fileList,
  };
  return (
    <Card>
      <Form form={form} onFinish={onFinish} name="Offer" layout="vertical">
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter offer name' }]}
        >
          <Input />
        </Form.Item>
        {/* <Row> */}
        <Row>
          <Col span={8}>
            <Image width={200} src={banner.image_url} />
          </Col>
          <Col span={8}>
            <Form.Item
              name="image"
              label="Upload Banner Image"
              rules={[{ required: true, message: 'Please enter image' }]}
            >
              <Upload listType="picture" accept="image/*" {...props}>
                <Button icon={<UploadOutlined />}>Click to upload</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              style={{ width: 300 }}
              name="is_active"
              label="Status"
              rules={[{ required: true, message: 'Please enter user status' }]}
            >
              <Select>
                <Select.Option value={1}>Active</Select.Option>
                <Select.Option value={0}>Inactive</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item name="valid_from" label="Valid From">
              <DatePicker
                name="valid_from"
                style={{ width: 300 }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                disabledDate={current =>
                  current && current < moment().subtract(1, 'days')
                }
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="valid_to" label="Valid To">
              <DatePicker
                style={{ width: 300 }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                disabledDate={current =>
                  current && current < moment(form.getFieldValue('valid_from'))
                }
              />
            </Form.Item>
          </Col>
        </Row>

        {/* </Row> */}

        <Title style={{ paddingTop: '20px' }}>Add Store Level Filter</Title>

        <Form.Item name="store_id" label="Store Id">
          <AsyncSelect mode="multiple" placeholder="Search here" url="stores" />
        </Form.Item>
        <Form.Item name="tag_id" label="Store Tag">
          <AsyncSelect
            mode="multiple"
            placeholder="Search here"
            url="store-tag"
          />
        </Form.Item>

        <Row justify="end">
          <Col sm={24} xs={24}>
            <Button
              block
              type="primary"
              onClick={form.submit}
              loading={loading}
            >
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BannerDetails;

import { PaymentActionType } from '../../actions/ActionTypes';

interface TransactionsState {
  transactions: any;
  transaction: any;
  refundTransaction: any;
  refundTransactions: any;
  disputes: any;
  dispute: any;
  isDisputeAccepted: boolean;
  isContestDisputeUpdated: boolean;
  isDisputeEvidenceUpdate: boolean;
  downtimes: any;
  downtimesLoader: boolean;
  transactionDetailLoader: boolean;
  transactionsLoader: boolean;
  isError: boolean;
  paymentLoading: boolean;
  paymentUpdate: boolean;
  paymentFailure: boolean;
  disputeLoading: boolean;
  transactionId: string;
  qrcodes: any;
  qrcodesLoader: boolean;
  qrCode: any;
  qrCodeLoader: boolean;
}

const initialState: TransactionsState = {
  transactions: {},
  transaction: {},
  refundTransaction: {},
  refundTransactions: {},
  disputes: {},
  dispute: {},
  isDisputeAccepted: false,
  isContestDisputeUpdated: false,
  isDisputeEvidenceUpdate: false,
  downtimes: {},
  downtimesLoader: false,
  transactionDetailLoader: false,
  transactionsLoader: false,
  isError: false,
  paymentLoading: false,
  disputeLoading: false,
  paymentUpdate: false,
  paymentFailure: false,
  transactionId: '',
  qrcodes: [],
  qrcodesLoader: false,
  qrCode: null,
  qrCodeLoader: false,
};
const reducer = (state: TransactionsState = initialState, action: any = {}) => {
  switch (action.type) {
    case PaymentActionType.GET_DISPUTES: {
      return {
        ...state,
        disputeLoading: true,
      };
    }

    case PaymentActionType.DISPUTES_SUCCESS: {
      return {
        ...state,
        disputes: action.payload,
        disputeLoading: false,
      };
    }

    case PaymentActionType.DISPUTES_FAILED: {
      return {
        ...state,
        disputes: {
          ...state.disputes,
        },
        disputeLoading: false,
        isError: true,
      };
    }

    case PaymentActionType.GET_DISPUTES_DETAILS: {
      return {
        ...state,
        disputeLoading: true,
      };
    }
    case PaymentActionType.GET_DISPUTES_DETAILS_SUCCESS: {
      return {
        ...state,
        dispute: action.payload,
        disputeLoading: false,
      };
    }
    case PaymentActionType.GET_DISPUTES_DETAILS_FAILED: {
      return {
        ...state,
        dispute: {
          ...state.dispute,
        },
        disputeLoading: false,
      };
    }

    case PaymentActionType.ACCEPT_DISPUTE: {
      return {
        ...state,
        disputeLoading: false,
        isDisputeAccepted: false,
      };
    }

    case PaymentActionType.ACCEPT_DISPUTE_SUCCESS: {
      return {
        ...state,
        disputeLoading: true,
        isDisputeAccepted: true,
      };
    }

    case PaymentActionType.ACCEPT_DISPUTE_FAILED: {
      return {
        ...state,
        transactions: {
          ...state.disputes,
        },
        disputeLoading: false,
        isError: true,
      };
    }

    case PaymentActionType.CONTEST_DISPUTE: {
      return {
        ...state,
        disputeLoading: true,
        isContestDisputeUpdated: false,
      };
    }

    case PaymentActionType.CONTEST_DISPUTE_SUCCESS: {
      return {
        ...state,
        disputeLoading: true,
        isContestDisputeUpdated: true,
      };
    }

    case PaymentActionType.CONTEST_DISPUTE_FAILED: {
      return {
        ...state,
        transactions: {
          ...state.disputes,
        },
        disputeLoading: false,
        isError: true,
      };
    }

    case PaymentActionType.DISPUTE_EVIDENCE: {
      return {
        ...state,
        isDisputeEvidenceUpdate: false,
      };
    }

    case PaymentActionType.DISPUTE_EVIDENCE_SUCCESS: {
      return {
        ...state,
        isDisputeEvidenceUpdate: true,
      };
    }

    case PaymentActionType.DISPUTE_EVIDENCE_FAILED: {
      return {
        ...state,
        isError: true,
      };
    }

    case PaymentActionType.GET_TRANSACTIONS: {
      return {
        ...state,
        transactionsLoader: true,
      };
    }

    case PaymentActionType.TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactions: action.payload,
        transactionsLoader: false,
      };
    }
    case PaymentActionType.TRANSACTIONS_FAILED: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
        },
        transactionsLoader: false,
        isError: true,
      };
    }
    case PaymentActionType.GET_TRANSACTIONS_DETAILS: {
      return {
        ...state,
        transactionDetailLoader: true,
      };
    }
    case PaymentActionType.TRANSACTIONS_DETAIL_SUCCESS: {
      return {
        ...state,
        transaction: action.payload,
        transactionDetailLoader: false,
      };
    }
    case PaymentActionType.TRANSACTIONS_DETAIL_FAILED: {
      return {
        ...state,
        transaction: {
          ...state.transaction,
        },
        transactionDetailLoader: false,
      };
    }
    case PaymentActionType.GET_REFUND_TRANSACTIONS: {
      return {
        ...state,
        transactionsLoader: true,
      };
    }
    case PaymentActionType.REFUND_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        refundTransactions: action.payload,
        transactionsLoader: false,
        isError: false,
      };
    }

    case PaymentActionType.REFUND_TRANSACTIONS_FAILED: {
      return {
        ...state,
        refundTransactions: {
          ...state.refundTransactions,
        },
        isError: true,
        transactionsLoader: false,
      };
    }

    case PaymentActionType.GET_REFUND_TRANSACTIONS_DETAILS: {
      return {
        ...state,
        transactionDetailLoader: true,
        refundTransaction: {},
      };
    }
    case PaymentActionType.REFUND_TRANSACTIONS_DETAIL_SUCCESS: {
      return {
        ...state,
        refundTransaction: action.payload,
        transactionDetailLoader: false,
      };
    }
    case PaymentActionType.REFUND_TRANSACTIONS_DETAIL_FAILED: {
      return {
        ...state,
        refundTransaction: {
          ...state.refundTransaction,
        },
        transactionDetailLoader: false,
      };
    }
    case PaymentActionType.GET_UPDATE_PAYMENT_STATUS: {
      return {
        ...state,
        paymentLoading: true,
      };
    }
    case PaymentActionType.GET_UPDATE_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        paymentLoading: false,
        paymentUpdate: true,
      };
    }
    case PaymentActionType.GET_UPDATE_PAYMENT_STATUS_FAILED: {
      return {
        ...state,
        paymentLoading: false,
        paymentFailure: true,
      };
    }
    case PaymentActionType.RESET_PAYMENT_STATUS: {
      return {
        ...state,
        paymentLoading: false,
        paymentUpdate: false,
        paymentFailure: false,
      };
    }
    case PaymentActionType.GET_DOWNTIMES: {
      return {
        ...state,
        downtimesLoader: true,
      };
    }
    case PaymentActionType.GET_DOWNTIMES_FAILED: {
      return {
        ...state,
        downtimesLoader: false,
      };
    }
    case PaymentActionType.GET_DOWNTIMES_SUCCESS: {
      return {
        ...state,
        downtimes: action.payload,
        downtimesLoader: false,
      };
    }
    case PaymentActionType.GET_TRANSACTION_FROM_PAYMENT_ID: {
      return {
        ...state,
        transactionId: '',
      };
    }
    case PaymentActionType.GET_TRANSACTION_FROM_PAYMENT_ID_SUCCESS: {
      return {
        ...state,
        tansactionId: action.payload.id,
      };
    }
    case PaymentActionType.GET_TRANSACTION_FROM_PAYMENT_ID_FAILURE: {
      return {
        ...state,
        tansactionId: '',
      };
    }
    case PaymentActionType.GET_QR_CODES: {
      return {
        ...state,
        qrcodesLoader: true,
      };
    }

    case PaymentActionType.GET_QR_CODES_FAILED: {
      return {
        ...state,
        qrcodesLoader: false,
      };
    }

    case PaymentActionType.GET_QR_CODES_SUCCESS: {
      return {
        ...state,
        qrcodes: action.payload.data,
        qrcodesLoader: false,
      };
    }
    case PaymentActionType.GET_QR_DETAILS: {
      return {
        ...state,
        qrCodeLoader: true,
      };
    }

    case PaymentActionType.GET_QR_DETAILS_FAILED: {
      return {
        ...state,
        qrCodeLoader: false,
      };
    }

    case PaymentActionType.RESET_QR_DETAILS: {
      return {
        ...state,
        qrCode: null,
        qrCodeLoader: false,
      };
    }

    case PaymentActionType.GET_QR_DETAILS_SUCCESS: {
      return {
        ...state,
        qrCode: action.payload,
        qrCodeLoader: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
